import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import YAMLGlobal from "../content/global.yaml"
import YAMLContent from "../content/aboutPage.yaml"
import YAMLSubscription from "../content/homePage.yaml"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockPress from "../components/AboutPage/InThePress/InThePress"
import Subscription from "../components/HomePage/Subscription/Subscription"
import Pagination from "../components/Blog/Pagination/Pagination"
import axios from "axios"
import { ADMIN_ROUTE } from "../constants"

const BlogPage = ({ location, data, pageContext }) => {
  const { humanPageNumber, numberOfPages, previousPagePath, nextPagePath } =
    pageContext

  const seo = {
    title: data.seo.seo_title,
    description: data.seo.seo_description,
    keywords: data.seo.seo_keywords,
    image: data.seo.seo_image.localFile.childImageSharp.fixed.src,
  }

  const blog = data.blog.edges

  const subscriptionDescription = data.home.subscription_description
  const subscriptionAttention = data.home.subscription_attention

  const [searchValue, setSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (searchValue.length > 0) {
        setLoading(true)

        const response = await axios.get(
          `${ADMIN_ROUTE}/blog/filter?title=${searchValue}`
        )

        const results = response.data.data.map(el => ({ node: el }))

        setSearchResults(results)

        setLoading(false)
      } else {
        setSearchResults(null)
      }
    })()
  }, [searchValue])

  return (
    <Layout location={location}>
      <SEO
        title={`${seo.title}${
          numberOfPages > 1
            ? ` | Page: ${humanPageNumber}/${numberOfPages}`
            : ""
        }`}
        description={seo.description}
        keywords={seo.keywords}
        image={seo.image}
      />

      <BlockPress
        header={YAMLContent.press.header}
        button={YAMLGlobal.readMore}
        noCategory={YAMLContent.press.noCategory}
        setSearchValue={setSearchValue}
        data={searchResults ? searchResults : blog}
        isBig
        loading={loading}
      />

      {searchValue.length === 0 && (
        <Pagination
          parentPath="/blog"
          nextPage={nextPagePath}
          previousPage={previousPagePath}
          countOfPages={numberOfPages}
          currentPage={humanPageNumber}
        />
      )}
      <Subscription
        description={subscriptionDescription}
        attention={subscriptionAttention}
        header={YAMLSubscription.subscription.header}
        email={YAMLSubscription.subscription.email}
        button={YAMLGlobal.subscribe}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    seo: directusBlogSeoEng {
      seo_title
      seo_description
      seo_keywords
      seo_image {
        localFile {
          childImageSharp {
            fixed(quality: 90, fit: COVER, height: 630, width: 1200) {
              src
            }
          }
        }
      }
    }
    home: directusHomeEng {
      subscription_description
      subscription_attention
    }
    blog: allDirectusBlogEng(
      sort: { fields: created_on, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          category
          created_on(formatString: "MMM DD, YYYY")
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          description
          slug
        }
      }
    }
  }
`

export default BlogPage
